<script setup lang="ts">
import {ref, watch} from 'vue';
import qs from 'qs';
import debounce from "lodash.debounce";

const props = defineProps(['category_id', 'store_id', 'no_non_exclusive_builds']);
const products = ref([]);
const meta = ref({});
const url = useRuntimeConfig().public.baseURL + '/products';
const filters = ref({
  category_ids: [],
  tag_ids: [],
  store_ids: [],
  excluded_store_ids: [],
  search: null,
  min_price: 0,
  max_price: 300,
  sort_by: 'newest',
  page: 1,
});

const fetchProducts = async () => {
  const usedFilters = JSON.parse(JSON.stringify(filters.value));
  usedFilters.category_ids = props.category_id ? [props.category_id] : usedFilters.category_ids;
  usedFilters.store_ids = props.store_id ? [props.store_id] : usedFilters.store_ids;

  if (props.no_non_exclusive_builds) {
    usedFilters.non_exclusive_build = false;
  }

  const {data, pending, error} = await useFetch(url + '?' + qs.stringify(usedFilters, {arrayFormat: 'brackets'}), {
    watch: false,
  });
  products.value = data.value.data;
  meta.value = data.value.meta;
};

const debouncedWatch = debounce(() => {
  fetchProducts()
}, 500);

watch(filters, debouncedWatch, {deep: true})

onBeforeUnmount(() => {
  debouncedWatch.cancel();
})

await fetchProducts();

</script>

<template>
  <ProductFilters v-model="filters" :meta="meta" :show-stores="store_id == null" :show-categories="category_id == null">
    <div v-if="products"
         class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-4 mt-2">
      <ProductCard v-for="product in products" :key="product.id" :product="product"/>
    </div>
  </ProductFilters>
</template>

<style scoped>

</style>